// withLazyLoad.js
import React, { useState, useEffect } from 'react';

const withLazyLoad = (Component) => {
  return (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = React.useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Stop observing once the component is loaded
          }
        },
        { threshold: 0.1 }
      );

      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);

    const skeletonStyle = {
      backgroundColor: '#f0f0f0',
      height: '200px', // Adjust this based on the section height
      borderRadius: '8px',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    };

    return (
      <div ref={sectionRef}>
        {isVisible ? <Component {...props} /> : <div style={skeletonStyle}>Loading...</div>}
      </div>
    );
  };
};

export default withLazyLoad;
