import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      <link rel="canonical" href="http://meguide.ae" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://meguide.ae" />
      <meta property="og:image" content="http://meguide.ae/logo.png" />
      <meta name="keywords" content="MeGuide, trading, UAE, agro commodities, products, contact" />
    </Helmet>
  );
};

export default SEO;
