// App.js
import React, { useState, lazy, Suspense, startTransition } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import SEO from './components/SEO';
import LoadingSpinner from './components/LoadingSpinner'; 
import Modal from './components/Modal'; 
import withLazyLoad from './components/WithLazyLoad';

const Home = lazy(() => import('./sections/Home'));
const AboutUs = lazy(() => import('./sections/AboutUs'));
const Products = lazy(() => import('./sections/Products'));
const Contact = lazy(() => import('./sections/Contact'));

// Wrap sections with lazy load HOC
const LazyHome = withLazyLoad(Home);
const LazyAboutUs = withLazyLoad(AboutUs);
const LazyProducts = withLazyLoad(Products);
const LazyContact = withLazyLoad(Contact);

function App() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openContactForm = () => {
    startTransition(() => {
      setShowContactForm(true);
    });
  };

  const openModal = (productName) => {
    startTransition(() => {
      setSelectedProduct(productName);  // Set the selected product
      setShowModal(true);
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProduct(null);  // Reset the selected product
  };

  return (
    <>
      <SEO title="MeGuide - Trading" description="Leading agro commodities trading company based in UAE." />
      <div className="App">
        <style>
          {`
            .skeleton {
              background-color: #f0f0f0;
              height: 200px; /* Adjust this based on the section height */
              border-radius: 8px;
              margin-bottom: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
          `}
        </style>
        <Header onContactClick={openContactForm} />
        <main className="snap-y snap-mandatory overflow-y-scroll">
          <Suspense fallback={<LoadingSpinner />}>
            <section id="home" className="snap-start">
              <LazyHome />
            </section>
            <section id="about-us" className="snap-start">
              <LazyAboutUs />
            </section>
            <section id="products" className="snap-start">
              <LazyProducts onGetQuoteClick={openModal} />
            </section>
            <section id="contact" className="snap-start">
              <LazyContact showProducts={false} isGeneric={true} /> {/* General form below products without product checkboxes */}
            </section>
          </Suspense>
        </main>
        <Footer />
        <Modal show={showModal} onClose={closeModal}>
          <LazyContact onSubmitCallback={closeModal} selectedProduct={selectedProduct} showProducts={true} isGeneric={false} /> {/* Detailed form in the modal with product checkboxes */}
        </Modal>
      </div>
    </>
  );
}

export default App;
