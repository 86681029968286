import React from 'react';

function Footer() {
  return (
    <footer className="bg-[#009165] text-white py-4">
      <div className="container mx-auto text-center">
        <p>© 2024 MeGuide Co. Ltd. All rights reserved.</p>
        <p>Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates</p>
        <p>Email: info@meguide.ae | Phone: +971564708649</p>
      </div>
    </footer>
  );
}

export default Footer;
