import React, { useState } from 'react';
import { FaWhatsapp, FaPhone, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../assets/logo.png'; // Adjusted the path

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    setIsMenuOpen(false); // Close the menu after selecting an option
  };

  return (
    <header className="p-6 fixed w-full z-50 flex justify-between items-center bg-gray-100">
      {/* Logo */}
      <div className="text-2xl font-bold text-[#009165] cursor-pointer flex items-center" onClick={() => scrollToSection('home')}>
        <img src={logo} alt="MeGuide Logo" className="h-20" /> {/* Increased the height for an even larger logo */}
      </div>

      {/* Hamburger Menu for Small Devices */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-2xl text-blue-500 focus:outline-none">
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Navigation */}
      <ul className={`flex-col md:flex-row md:flex space-x-8 text-[#003A92] text-lg font-semibold fixed md:static top-20 right-0 bg-gray-100 p-6 md:p-0 transition-transform duration-300 transform md:transform-none ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} md:translate-x-0`}>
        <li 
          className="cursor-pointer hover:text-[#009165] transition duration-300" 
          onClick={() => scrollToSection('home')}
        >
          Home
        </li>
        <li 
          className="cursor-pointer hover:text-[#009165] transition duration-300" 
          onClick={() => scrollToSection('about-us')}
        >
          About Us
        </li>
        <li 
          className="cursor-pointer hover:text-[#009165] transition duration-300" 
          onClick={() => scrollToSection('products')}
        >
          Our Products
        </li>
        <li 
          className="cursor-pointer hover:text-[#009165] transition duration-300" 
          onClick={() => scrollToSection('contact')}
        >
          Contact Us
        </li>
      </ul>

      {/* Contact Icons */}
      <div className="hidden md:flex space-x-6 text-2xl text-[#003A92]">
        <a href="https://wa.me/+971564708649" target="_blank" rel="noopener noreferrer" className="hover:text-[#009165] transition duration-300">
          <FaWhatsapp />
        </a>
        <a href="tel:+971564708649" className="hover:text-[#009165] transition duration-300">
          <FaPhone />
        </a>
        <a href="mailto:info@meguide.ae" className="hover:text-[#009165] transition duration-300">
          <FaEnvelope />
        </a>
      </div>
    </header>
  );
};

export default Header;
